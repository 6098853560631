@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

:root {
  --footer-bg-color: #000000;
  --footer-text-color: #ffffff;
  --footer-icon-color: #ffffff;
  --footer-font-family: 'Poppins', sans-serif;
}

.footer {
  background-color: var(--footer-bg-color);
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  font-family: var(--footer-font-family);
}

/* Links e texto do footer */
.footer-link, 
.footer-social span {
  color: var(--footer-text-color);
  font-size: 1rem;
  text-decoration: none;
  font-family: var(--footer-font-family);
}

/* Estilo para ícones sociais */
.footer-social {
  display: flex;
  align-items: center;
}

.social-icon {
  color: var(--footer-icon-color);
  font-size: 1.5rem;
  margin-right: 8px;
}

/* Hover no link */
.footer-link:hover, 
.footer-social span:hover {
  text-decoration: underline;
}

/* Responsividade - Quebra o layout para celular */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-link, 
  .footer-social {
    margin-bottom: 15px;
  }
}
