:root {
    --navbar-margin: 20px;
    --font-family: 'Poppins', sans-serif;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--font-family);
    padding: 10px var(--navbar-margin);
    background-color: #000000; /* Fundo preto para combinar com o footer */
  }
  
  .navbar-left,
  .navbar-right {
    flex: 1;
  }
  
  .navbar-image {
    max-width: 650px;
    height: auto;
  }
  
  .navbar-left {
    display: flex;
    justify-content: flex-start;
  }
  
  .navbar-right {
    display: flex;
    justify-content: flex-end;
  }
  
  /* Responsividade para dispositivos móveis */
  @media (max-width: 768px) {
    .navbar-image {
      max-width: 170px; /* Imagens menores para telas móveis */
    }
  
    .navbar {
      padding: 0px 15px;
    }
  }
  