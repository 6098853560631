@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

:root {
  --content-bg-color: #ffffff;
  --content-left-bg-color: #000000;
  --content-text-color: #000000;
  --content-left-text-color: #ffffff;
  --content-font-family: 'Poppins', sans-serif;
  --video-max-width: 90%; /* Tamanho ajustado do vídeo */
  --grafismo-max-width: 100%; /* Mesmo width para o grafismo */
  --heading-color: #e97132; /* Cor laranja para os títulos h2 */
  --text-size: 1.3rem; /* Tamanho base do texto */
  --text-weight: 600; /* Letras mais grossas */
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow-x: hidden;
}

.content-left {
  background-color: var(--content-left-bg-color);
  color: var(--content-left-text-color);
  flex: 0.22; /* Reduzido ainda mais o espaço preto */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px 0 -30px;
  border-right: solid;
}

.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-cristina {
  max-width: var(--video-max-width);
  height: auto;
  margin-bottom: 20px;
}

.content-image {
  max-width: var(--grafismo-max-width);
  height: auto;
}

/* Nome e cargo de Cristina Castilho */
.cristina-info {
  color: var(--content-left-text-color);
  font-family: var(--content-font-family);
  margin-bottom: 50px;
}

.cristina-info .name {
  font-weight: bold;
  font-size: 1.2rem;
}

.cristina-info .position {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

.content-right {
  background-color: var(--content-bg-color);
  color: var(--content-text-color);
  font-family: var(--content-font-family);
  flex: 0.7;
  padding: 20px;
}

.content-right h2 {
  font-size: var(--text-size);
  font-weight: var(--text-weight);
  margin-top: 20px;
  color: var(--heading-color);
}

.content-right p {
  font-size: var(--text-size);
  line-height: 1.6;
  font-weight: var(--text-weight);
}

/* Responsividade - Telas menores */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
    align-items: center;
  }

  .content-left, .content-right {
    width: 100%;
  }

  .content-right P{
    padding:  0 2rem!important;
  }

  .content-right h2{
    padding:  0 2rem!important;
  }

  .video-cristina, 
  .content-image {
    width: 70%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .content-right h2 {
    font-size: 1.3rem;
  }

  .content-right p {
    font-size: 1rem;
  }
  
  .cristina-info .name {
    font-weight: bold;
    font-size: 1.2rem !important;
  }
  
  .cristina-info .position {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem !important;
    font-weight: 500;
  }
}

/* Configuração para telas menores que 1024px (20% menor) */
@media (max-width: 1024px) {
  .content-right h2 {
    font-size: calc(var(--text-size) * 0.9); /* 20% menor */
  }

  .content-right p {
    font-size: calc(var(--text-size) * 0.72); /* 20% menor */
  }

  .cristina-info {
    font-size: calc(var(--text-size) * 0.2);
    text-align: center;
  }

  .cristina-info .position{
    font-size: calc(var(--text-size) * 0.4);
    text-align: center;
  }
}

/* Configuração para telas maiores ou iguais a 1440px (Texto maior) */
@media (min-width: 1440px) {
  .content-right h2 {
    font-size: calc(var(--text-size) * 1.3); /* Texto 30% maior */
  }

  .content-right p {
    font-size: calc(var(--text-size) * 1.3); /* Texto 30% maior */
  }
}
